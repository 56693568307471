<template>
    <div style="min-height:100vh;background:#F8F8F8;">
        <div class="top_zong">
            <div class="top_beijn">
                <!-- <div class="top_beijn_on"></div> -->
            </div>
            <div class="top_cont_fa">
                <!-- <div class="kongbaichu1"></div> -->
                <div class="top_cont_fa_pic">
                    <div class="kongbaichu2"></div>
                    <div class="top_cont_fa_pic_title">
                        <div class="top_cont_fa_pic_title_weniz">钱包余额(元）</div>
                        <div class="top_cont_fa_pic_title_tu"></div>
                    </div>
                    <div class="top_cont_fa_mony">{{details.balance}}</div>
                    <div class="top_cont_fa_litter">
                        <div class="top_cont_fa_litter_tu"></div>
                        <div class="top_cont_fa_litter_wenz">保障中</div>
                    </div>
                </div>
                <!-- -->
                <div class="top_money_fa">
                    <div>
                        <div>
                            <div class="top_cont_fa_shouqu_tu"></div>
                            <div class="top_cont_fa_shouqu_wenI">总收入</div>
                        </div>
                        <div class="top_cont_fa_shouqu_money">{{details.revenue}}</div>
                    </div>
                    <div>
                        <div>
                            <div class="top_cont_fa_shouqu_tu" style="background:#F62E2E;"></div>
                            <div class="top_cont_fa_shouqu_wenI">已提现</div>
                        </div>
                        <div class="top_cont_fa_shouqu_money">{{details.expenditure}}</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="walletills_scorr">
            <div class="walletills_scorr_title">
                <div class="walletills_scorr_title_on" @click="shouzhi_show=true">
                    <div class="walletills_scorr_title_on_wenzi">收支</div>
                    <div class="walletills_scorr_title_on_pic"></div>
                    <div class="walletills_scorr_title_on_tan" v-if="shouzhi_show">
                        <div class="walletills_scorr_title_on_tan_kb"></div>
                        <div class="walletills_scorr_title_on_tan_cd" v-for="(item,index) in shouzhi_data" :key="index" @click.stop="data_check(item,'shouzhi_')">
                            <div class="walletills_scorr_title_on_tan_cd_wenz" :class="{'col_0ABBB9':item.key == shouzhi_val}">{{item.value}}</div>
                            <div v-if="item.key == shouzhi_val" class="walletills_scorr_title_on_tan_cd_tupian"></div>
                        </div>
                    </div>
                </div>
                <div class="walletills_scorr_title_on " @click="leixin_show=true">
                    <div class="walletills_scorr_title_on_wenzi">类型</div>
                    <div class="walletills_scorr_title_on_pic"></div>
                    <div class="walletills_scorr_title_on_tan" v-if="leixin_show">
                        <div class="walletills_scorr_title_on_tan_kb"></div>
                        <div class="walletills_scorr_title_on_tan_cd" v-for="(item,index) in leixin_data" :key="index" @click.stop="data_check(item,'leixin_')">
                            <div class="walletills_scorr_title_on_tan_cd_wenz" :class="{'col_0ABBB9':item.key == leixin_val}">{{item.value}}</div>
                            <div v-if="item.key == leixin_val" class="walletills_scorr_title_on_tan_cd_tupian"></div>
                        </div>
                    </div>
                </div>
                <div class="walletills_scorr_title_on" @click="status_show=true">
                    <div class="walletills_scorr_title_on_wenzi">状态</div>
                    <div class="walletills_scorr_title_on_pic"></div>
                    <div class="walletills_scorr_title_on_tan1" v-if="status_show">
                        <div class="walletills_scorr_title_on_tan_kb"></div>
                        <div class="walletills_scorr_title_on_tan_cd" v-for="(item,index) in status_data" :key="index" @click.stop="data_check(item,'status_')">
                            <div class="walletills_scorr_title_on_tan_cd_wenz" :class="{'col_0ABBB9':item.key == status_val}">{{item.value}}</div>
                            <div v-if="item.key == status_val" class="walletills_scorr_title_on_tan_cd_tupian"></div>
                        </div>
                    </div>
                </div>
            </div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" @load="onLoadList(page)" :offset="100">
                    <div class="walletills_scorr_congt">
                        <div class="walletills_scorr_congt_cd" v-for="(item,index) in table_list" :key="index" @click="gotonext(item.id)">
                            <div class="walletills_scorr_congt_cd_on">
                                <div class="walletills_scorr_congt_cd_on_top_fa">
                                    <div class="list_top_lt">{{item.title}}</div>
                                    <div class="list_top_rt">
                                        
                                        <div v-if="item.status == 0 || item.status == 4" class="list_top_rt_btn" @click.stop="tixian_on(item,index)">提现</div>
                                        <div v-else class="list_top_rt_text">{{item.status_desc}}</div>
                                    </div>
                                </div>
                                <div class="walletills_scorr_congt_cd_on_top_fa mar_top">
                                    <div class="list_bot_lt">{{item.gmt_create}}</div>
                                    <div class="list_top_rt">
                                        <div v-if="item.money_type" class="list_top_rt_money col_0ABBB9">-{{item.amount}}</div>
                                        <div v-else class="list_top_rt_money col_F62E2E">+{{item.amount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>

        </div>
        <!-- -->
        <div class="bottom_back" @click="gobanck">
            <div class="bottom_back_on">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">确认提现</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">确认提现{{tixian_money}}元到微信零钱？</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt" @click="cancle_no">取消</div>
                    <div class="cancl_tan_on_bont_rt" @click="cancle_ok">确认</div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="select_tan" v-if="shouzhi_show||leixin_show||status_show" @click="sele_fa()"></div>
    </div>
</template>
<script>
import '@/assets/css/index.css'
// import { Toast } from 'vant';
// import { send_sms_get } from "@/api/login";
// import { getInfo, postInfo, mobile_bind } from "@/api/home";
export default {
    data() {
        return {
            finished: false,
            refreshing: false,
            loading: false,
            shouzhi_show: false,//收支
            shouzhi_val: 0,
            leixin_show: false,//类型
            leixin_val: 0,
            status_show: false,//状态
            status_val: 0,
            page: 1, //请求第几页
            shouzhi_data: [
                {
                    value: '全部',
                    key: 0,
                },
                {
                    value: '收入',
                    key: 1,
                },
                {
                    value: '体现',
                    key: 2,
                }
            ],
            leixin_data: [
                {
                    value: '全部',
                    key: 0,
                },
                {
                    value: '工资',
                    key: 1,
                },
                {
                    value: '借支',
                    key: 2,
                }
            ],
            status_data: [
                {
                    value: '全部',
                    key: 0,
                },
                {
                    value: '待体现',
                    key: 1,
                },
                {
                    value: '已提现',
                    key: 2,
                },
                {
                    value: '已撤回',
                    key: 3,
                }
            ],
            table_list: [],
            time: 0, // 倒计时
            details: {},
            my_lizhi: false,
            mobile: '', // 手机号码
            code: '', // 验证码
            tixian_money: 0,
            text_index: 0,
            url: '',
            is_form: 'home', // home，apply: 从home，apply过来的要请求接口，info：过来的不需要请求接口
            is_click: false
        }
    },

    mounted() {
        window.scrollTo(0, 0);
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.getList()
    },
    methods: {
        gobanck(){
            this.$router.go(-1);
        },
        gotonext(id) {
            this.$router.push({ path: '/monetdetail', query: { id } });
        },
        tixian_on(item, index) {
            this.tixian_money = item.amount;
            this.text_index = index;
            this.my_lizhi = true;
        },
        getList(page = 1) {
            if (page == 1) {
                this.table_list = []
            }
            if (this.refreshing) {
                this.refreshing = false
            }
            var data = {
                team_id: localStorage.getItem('team_id')
            }

            this.Request('client/jobseeker/wallet', 'get', data, true, false).then(res => {
                if (res.status == 0) {
                    this.table_list = this.table_list.concat(res.data.detail_list)
                    this.page++;
                    this.loading = false;
                    this.details = res.data.wallet
                    if (this.table_list.length >= 20) {
                        this.finished = true;
                    }
                }
            })
        },
        onRefresh() {
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.getList();
        },
        onLoadList(page) {
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            if (page != 1) {
                this.getList(page);
            }
        },
        sele_fa() {
            this.shouzhi_show = false;
            this.leixin_show = false;
            this.status_show = false;
        },
        data_check(item, type) {
            this[type + 'val'] = item.key;
            this[type + 'show'] = false;
        },

        save() {

        },
        //取消
        cancle_no() {
            this.my_lizhi = false;
        },
        cancle_ok() {
            var data ={
                detail_id: this.table_list[this.text_index].id,
                detail_type: this.table_list[this.text_index].detail_type,
            }
            this.Request('client/jobseeker/withdrawal','post',data).then(res=>{
                if(res.status == 0){
                
                    console.log(res)
                }
            })
        }
    }
}
</script>
<style scoped>
.walletills_scorr_congt {
    width: calc(100% - 80px);
    min-height: 600px;
    margin-left: 40px;
}
.walletills_scorr_congt_cd {
    width: 100%;
    height: 185px;
    border-top: 1px solid #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.walletills_scorr_congt_cd_on {
    width: 100%;
    height: 90px;
}
.walletills_scorr_congt_cd_on_top_fa {
    width: 100%;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.mar_top {
    margin-top: 10px;
}
.list_top_lt {
    width: 300px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.list_bot_lt {
    width: 300px;
    height: 35px;
    line-height: 35px;
    text-align: left;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}
.list_top_rt {
    width: calc(100% - 320px);
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.list_top_rt_money {
    min-width: 70px;
    height: 40px;
    line-height: 40px;
    text-align: right;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
}
.list_top_rt_btn {
    width: 68px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0abbb9;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 20px;
}
.list_top_rt_text {
    min-width: 70px;
    height: 40px;
    line-height: 40px;
    text-align: right;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #999999;
}
.walletills_scorr_title_on_tan_kb {
    width: 100%;
    height: 10px;
}
.walletills_scorr_title_on_tan_cd {
    width: calc(100% - 50px);
    height: 90px;
    margin: 0 auto;
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.walletills_scorr_title_on_tan_cd_tupian {
    width: 27px;
    height: 20px;
    background: url("../../assets/img/dun_baohu.png");
    background-size: 100% 100%;
}
.walletills_scorr_title_on_tan_cd_wenz {
    width: 150px;
    height: 90px;
    line-height: 90px;
    text-align: left;
    text-indent: 12px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.select_tan {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
}
.walletills_scorr_title {
    width: calc(100% - 80px);
    height: 95px;
    margin-left: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.walletills_scorr_title_on {
    width: 80px;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.walletills_scorr_title_on_tan1 {
    position: absolute;
    width: 290px;
    height: 395px;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
    opacity: 1;
    border-radius: 10px;
    bottom: -405px;
    left: -290px;
    z-index: 101;
}
.walletills_scorr_title_on_tan {
    position: absolute;
    width: 290px;
    height: 300px;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
    opacity: 1;
    border-radius: 10px;
    bottom: -310px;
    left: 0;
    z-index: 101;
}
.walletills_scorr_title_on_pic {
    width: 20px;
    height: 10px;
    background: url("../../assets/img/sanjiao.png");
    background-size: 100% 100%;
}
.walletills_scorr_title_on_wenzi {
    width: 60px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.walletills_scorr {
    width: calc(100% - 50px);
    min-height: 700px;
    margin-left: 25px;
    margin-top: 20px;
    background: #ffffff;
    opacity: 1;
    border-radius: 10px;
}
.top_cont_fa_shouqu {
    width: 300px;
    height: 35px;
    margin-left: calc(50% - 100px);
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.top_cont_fa_shouqu_tu {
    width: 8px;
    height: 8px;
    background: #0abbb9;
    border-radius: 50%;
}
.top_cont_fa_shouqu_wenI {
    width: 72px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    margin-left: 8px;
    color: #999999;
    opacity: 1;
}
.top_cont_fa_shouqu_money {
    /* width: 172px; */
    height: 33px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 33px;
    margin-left: 12px;
    color: #1a1a1a;
    opacity: 1;
}
.top_cont_fa_litter {
    width: 110px;
    height: 35px;
    margin: 40px auto;
    background: #eafcfc;
    opacity: 1;
    border-radius: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.top_cont_fa_litter_tu {
    width: 18px;
    height: 22px;
    margin-right: 4px;
    background: url("../../assets/img/dun_baohu.png");
    background-size: 100% 100%;
}
.top_cont_fa_litter_wenz {
    min-width: 54px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0abbb9;
}
.top_cont_fa_mony {
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    margin-top: 30px;
    font-size: 50px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1a1a1a;
}
.kongbaichu1 {
    width: 100%;
    /* height: 25px; */
}
.kongbaichu2 {
    width: 100%;
    height: 90px;
}
.top_cont_fa_pic_title {
    width: 100%;
    height: 35px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.top_cont_fa_pic_title_tu {
    width: 30px;
    height: 20px;
    background: url("../../assets/img/yanjin.png");
    background-size: 100% 100%;
}
.top_cont_fa_pic_title_weniz {
    min-width: 152px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}
.top_cont_fa_pic {
    width: 395px;
    height: 313px;
    margin: 0 auto;
    background: url("../../assets/img/jine_quan.png");
    background-size: 100% 100%;
}
.top_zong {
    width: 100%;
    height: 605px;
    position: relative;
}
.top_cont_fa {
    width: calc(100% - 50px);
    /* height: 495px; */
    background: #ffffff;
    border-radius: 10px;
    z-index: 10;
    position: absolute;
    padding: 30px 0;
    top: 110px;
    left: 25px;
}
.top_beijn {
    width: 100%;
    height: 364px;
    position: relative;
    top: 0;
    z-index: 0;
    border-radius: 0 0 65% 65%;
    background: #0abbb9;
}
.top_beijn_on {
    width: 100%;
    height: 364px;
    position: relative;
    z-index: 0;
    overflow: hidden;
}
.top_beijn_on::after {
    content: "";
    width: 120%;
    height: 364px;
    position: absolute;
    left: -10%;
    top: 0;
    border-radius: 0 0 65% 65%;
    background: #0abbb9;
}
.top_money_fa {
    display: flex;
    margin-top: 45px;
}
.top_money_fa > div {
    width: 50%;
    text-align: center;
}
.top_money_fa > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>